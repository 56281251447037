import request from '@/utils/request'


// 查询报名点列表
export function listSites(query) {
  return request({
    url: '/driving-school/driving-school-sites/list',
    method: 'get',
    params: query
  })
}

// 查询报名点分页
export function pageSites(query) {
  return request({
    url: '/driving-school/driving-school-sites/page',
    method: 'get',
    params: query
  })
}

// 查询报名点详细
export function getSites(data) {
  return request({
    url: '/driving-school/driving-school-sites/detail',
    method: 'get',
    params: data
  })
}

// 新增报名点
export function addSites(data) {
  return request({
    url: '/driving-school/driving-school-sites/add',
    method: 'post',
    data: data
  })
}

// 修改报名点
export function updateSites(data) {
  return request({
    url: '/driving-school/driving-school-sites/edit',
    method: 'post',
    data: data
  })
}

// 删除报名点
export function delSites(data) {
  return request({
    url: '/driving-school/driving-school-sites/delete',
    method: 'post',
    data: data
  })
}
